<template>
  <main>
    <div class="header-backdrop"></div>
    <div id="header">
      <div class="header-title">Login</div>
      <div class="hamburger-menu">
        <div class="user-key f1"></div>
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
      </div>
      <nav id="menu-nav" tabindex="-1">
        <ul>
        </ul>
      </nav>
    </div>
    <section id="login" tabindex="-1">
      <div class="container">
        <div class="row text-center">
          <div class="col-12 pb-5">
            <p>Scansiona il Token di accesso<br>
              per eseguire il Login</p>
          </div>
        </div>
        <form novalidate onsubmit="return false">
          <div class="row mt-4">
            <div class="col-12">
              <div class="form-group">
                <input type="text" id="token" v-on:keyup="checkEnterValue" required v-model="token">
                <span class="highlight"></span>
                <span class="bar"></span>
                <label class="required">Token di Accesso</label>
              </div>
              <div class="error">{{errore_login}}</div>
            </div>
          </div>
        </form>
      </div>
    </section>
  </main>
</template>

<script>
  //Importo lo store
  import store from "@/vuex/store";

  //Importo il logistix.config.js
  const config = require('./logistix.config');

  export default {
    name: "app",
    methods: {
      loginUser: function() {
        fetch(config.testApiUrl.proxy + config.testEndpoints.login, {
          method: 'post',
          headers: {
            'X-AUTH-TOKEN': this.token
          }
        })
          .then((response) => {
            if (response) {
              //
            }
            return response.json()
          })
          .then((jsonData) => {
            if(jsonData.error!=undefined) {
              if(jsonData.error === 1) {
                this.errore_login = 'Errore, token vuoto, leggi o inserisci in token di accesso!'
                this.token = ''
              }
              else if(jsonData.error === 2) {
                this.errore_login = 'Errore, token non riconosciuto!'
                this.token = ''
              }
            }
            else {
              var rightRole = false
              jsonData.ruolo.forEach(ruolo => {
                if (ruolo === 'ROLE_OPERATOR') {
                  rightRole = true
                }
              });
              if (rightRole) {
                store.state.login.iduser = jsonData.cod_utente_gest;
                localStorage.operatore = jsonData.nome + " " + jsonData.cognome;
                localStorage.iduser = jsonData.cod_utente_gest;
                this.$router.push('/');
              }
              else {
                this.errore_login = 'Errore, il token corrisponde ad un utente non operatore!'
                this.token = ''
              }
            }
          })
      },
      checkEnterValue: function(event) {
        console.log('A',this.token)
        if(!this.token) {
          this.errore_login = 'Errore, inserisci il token di accesso!'
        }
        if(event.key === 'Enter' && this.token) {
          this.loginUser();
        }
      }
    },
    data() {
      return {
        errore_login: '',
        token: ''
      }
    }
  }
</script>